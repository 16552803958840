<template>
  <component :is="'b-card'">
    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <!-- Media -->
        <b-media class="mt-5">
          <template #aside>
            <div class="text-center mb-2">
              <am-profile-photo
                v-show="!updatingPhoto"
                size="90"
                variant="light-primary"
                :photo="photo"
              />
              <b-spinner
                v-show="updatingPhoto"
                style="width: 90px; height: 90px;"
                variant="primary"
              />
            </div>
          </template>
          <div style="margin: 10px 0px;">
            <b>{{ authUser.first_name }} {{ authUser.last_name }}</b>
          </div>
          <div class="d-flex flex-wrap">
            <am-file-button
              label="Change"
              @onFileUpload="updatePhoto"
            />
            <am-button
              variant="outline-danger"
              class="ml-1"
              icon="XIcon"
              label="Remove"
              @click="removeUserPhoto()"
            />
          </div>
        </b-media>
        <validation-observer
          ref="bankForm"
          v-slot="{invalid}"
        >
          <error-display :error="error" />
          <div class="row mt-2">
            <am-responsive>
              <am-input
                v-model="profile.first_name"
                name="first_name"
                label="First Name"
                rules="required|max_length:50"
              />
            </am-responsive>
            <am-responsive>
              <am-input
                v-model="profile.last_name"
                name="last_name"
                label="Last Name"
                rules="required|max_length:50"
              />
            </am-responsive>
            <am-responsive>
              <am-input
                v-model="profile.phone"
                name="phone"
                label="Phone"
                rules="required|max_length:11"
              />
            </am-responsive>
            <am-responsive>
              <am-input
                v-model="profile.email"
                name="email"
                label="Email"
                rules="required|email"
              />
            </am-responsive>
          </div>
          <am-button
            label="Save"
            class="float-right"
            :disabled="invalid"
            :loading="userFormLoading"
            @click="saveUser()"
          />
        </validation-observer>
      </b-tab>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Security</span>
        </template>
        <validation-observer
          ref="bankForm"
          v-slot="{invalid}"
        >
          <error-display :error="errorPass" />
          <div class="row mt-2">
            <am-responsive>
              <am-input
                v-model="security.current_password"
                name="current_password"
                label="Current Password"
                placeholder="Current Password"
                rules="required"
              />
            </am-responsive>
            <am-responsive>
              <am-input
                v-model="security.password"
                name="password"
                label="Password"
                placeholder="Password"
                rules="required|min_length:6"
              />
            </am-responsive>
            <am-responsive>
              <am-input
                v-model="security.password_confirmation"
                name="password_confirmation"
                label="Confirm Password"
                placeholder="Password Confirmation"
                rules="required"
              />
            </am-responsive>
          </div>
          <am-button
            label="Save"
            class="float-right"
            :disabled="invalid"
            :loading="updatingPassword"
            @click="updateUserPassword()"
          />
        </validation-observer>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BMedia, BAvatar, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import showToast from '@/farmbusiness/mixins/showToast'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmFileButton from '@/farmbusiness/components/AmFileButton.vue'
import AmResponsive from '@/farmbusiness/components/AmResponsive.vue'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmProfilePhoto from '@/farmbusiness/components/AmProfilePhoto.vue'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay'

export default {
  components: {
    ErrorDisplay,
    AmProfilePhoto,
    AmInput,
    AmResponsive,
    AmFileButton,
    AmButton,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BAvatar,
    ValidationObserver,
    BSpinner,
  },
  mixins: [showToast],
  data() {
    return {
      error: {},
      errorPass: {},
      profile: {
        id: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
      },
      security: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
      userFormLoading: false,
      updatingPhoto: false,
      updatingPassword: false,
    }
  },
  computed: {
    photo() {
      return this.authUser.photo
    },
    authUser() {
      return this.$store.getters['auth/user']
    },
  },
  watch: {
    authUser() {
      this.setUser()
    },
  },
  mounted() {
    this.setUser()
  },
  methods: {
    setUser() {
      this.profile.id = this.authUser.id
      this.profile.first_name = this.authUser.first_name
      this.profile.last_name = this.authUser.last_name
      this.profile.email = this.authUser.email
      this.profile.phone = this.authUser.phone
    },
    saveUser() {
      this.error = {}
      this.userFormLoading = true
      this.$store.dispatch('auth/updateUser', this.profile).then(res => {
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.error = err
        this.showError('Something went wrong, please try again')
      }).finally(() => {
        this.userFormLoading = false
      })
    },
    updateUserPassword() {
      this.errorPass = {}
      this.updatingPassword = true
      this.$store.dispatch('auth/updatePassword', this.security).then(res => {
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.errorPass = err
        this.showError('Something went wrong, please try again')
      }).finally(() => {
        this.updatingPassword = false
      })
    },
    removeUserPhoto() {
      this.updatingPhoto = true
      this.$store.dispatch('auth/removeUserPhoto').then(res => {
        this.showSuccess('Removed Successfully')
      }).catch(err => {
        this.showError('Something went wrong, please try again')
      }).finally(() => {
        this.updatingPhoto = false
      })
    },
    updatePhoto(e) {
      this.updatingPhoto = true
      const fd = new FormData()
      fd.append('photo', e.file)
      this.$store.dispatch('auth/updateUserPhoto', fd).then(res => {
        this.showSuccess('Saved Successfully')
      }).catch(err => {
        this.showError('Something went wrong, please try again')
      }).finally(() => {
        this.updatingPhoto = false
      })
    },
  },
}
</script>

<style>

</style>
